import { render, staticRenderFns } from "./CommonAside.vue?vue&type=template&id=a28ad4e6&scoped=true&"
import script from "./CommonAside.vue?vue&type=script&lang=js&"
export * from "./CommonAside.vue?vue&type=script&lang=js&"
import style0 from "./CommonAside.vue?vue&type=style&index=0&id=a28ad4e6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a28ad4e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/.jenkins/workspace/platform_preview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a28ad4e6')) {
      api.createRecord('a28ad4e6', component.options)
    } else {
      api.reload('a28ad4e6', component.options)
    }
    module.hot.accept("./CommonAside.vue?vue&type=template&id=a28ad4e6&scoped=true&", function () {
      api.rerender('a28ad4e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CommonAside.vue"
export default component.exports